import React from 'react'
import Link from 'gatsby-link'
import { graphql, StaticQuery } from "gatsby"
import "./thankyoumessage.scss"

const ThankYouMessage = (props) => {
  return (
    <div className="thankYouMsg">
      <div className="thankYouMsg__inner">
        Thank you for your inquiry. We will reach out to you shortly! <br />
        <Link to="/">Return Home</Link>
      </div>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        contactCow: file(relativePath: {eq: "contactcow.png"}) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ThankYouMessage data={data} {...props} />}
  />
)
