import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero";
import ServiceBoxes from "../components/serviceBoxes/serviceBoxes"
import ClientList from "../components/clientList/clientList"
import ThankYouMessage from "../components/thankyoumessage/thankyoumessage";

class ThankYouPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Thank You" />
        <Hero>
          <ThankYouMessage />
        </Hero>
        <ServiceBoxes />
        <ClientList />
    </Layout>
    )
  }
}

export default ThankYouPage
